import React from 'react'

const useFacebook = () => {
  React.useEffect(() => {
    const facebookScript = document.createElement('script')

    facebookScript.id = 'fb-sdk'
    facebookScript.async = true
    facebookScript.defer = true
    facebookScript.crossOrigin = 'anonymous'
    facebookScript.nonce = 'sATS0cUq'
    facebookScript.src = `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0&appId=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}&autoLogAppEvents=1`

    document.body.appendChild(facebookScript)

    if (window.FB) {
      FB.XFBML.parse()
    }

    return () => {
      document.body.removeChild(facebookScript)
    }
  }, [])
}

export default useFacebook
