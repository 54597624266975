import React from 'react'
import classnames from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { MaxWidthConstraint, Spacer } from '@revelrygroup/components'

import { LightDarkVariant, Quote } from '../../types'
import { OutlineRightArrowButton } from '../buttons/OutlineRightArrowButton'
import { OutlineLeftArrowButton } from '../buttons/OutlineLeftArrowButton'
import styles from './styles.module.scss'
import { useCarousel } from '../../hooks/useCarousel'

interface Props {
  itemClassName?: string
  quotes: Quote[]
  variant: LightDarkVariant
}

export const QuoteCarousel: React.FC<Props> = ({ quotes, variant }) => {
  const { activeIndex, currentItem, next, previous } = useCarousel<Quote>(quotes)

  return (
    <TransitionGroup className={styles.list}>
      <OutlineLeftArrowButton
        aria-label="Previous"
        onClick={() => previous()}
        type="button"
        variant={variant}
      />

      <CSSTransition
        key={activeIndex}
        classNames={{
          enter: styles.itemEnter,
          enterActive: styles.itemEnterActive,
          exit: styles.itemExit,
          exitActive: styles.itemExitActive,
        }}
        timeout={1000}
      >
        <figure className={classnames(styles.item, styles[`item${variant}`])}>
          <MaxWidthConstraint maxWidth={975}>
            <Spacer verticalBottom="xs">
              <blockquote className="h4">&ldquo;{currentItem.body}&rdquo;</blockquote>
            </Spacer>

            <figcaption className="h5">- {currentItem.author}</figcaption>
          </MaxWidthConstraint>
        </figure>
      </CSSTransition>

      <OutlineRightArrowButton aria-label="Next" onClick={() => next()} variant={variant} />
    </TransitionGroup>
  )
}
