import React from 'react'

import { LightDarkVariant, Quote } from '../../types'
import { QuoteCarousel } from '../QuoteCarousel'
import * as Showcase from '../Showcase'
import styles from './style.module.scss'

const DEFAULT_TESTIMONIALS: Quote[] = [
  {
    body:
      'Thank you for your kindness in helping us celebrate my father. We really appreciate everything you did to guide us. The celebrations at the funeral home and cemetery were very nice, and you even made sure the sun was shining that day. Thank you again.',
    author: 'Denise M.',
  },
  {
    body:
      'The staff at Kalas was so very accommodating and helpful. The personal attention you gave us was greatly appreciated. Kalas is a wonderful place to say good bye to a loved one.',
    author: 'Lucy M.',
  },
  {
    body:
      'I wanted to express our thanks for helping us with my dad’s viewing, funeral, and cemetery ceremony—it was a perfect tribute to my dad’s wonderful life. I really appreciate the ways you let us personalize it for him as well. Most importantly—thank you for your time in coming to their home. Little did we know his days were so numbered. But by meeting with all of us, you gave him the opportunity to express his desires—and made it easy for us and that much more meaningful.',
    author: 'Gian D.',
  },
  {
    body:
      'Although our family has suffered a great loss, you made it so much easier for us through your good work and professionalism. The funeral was a beautiful tribute to our mother and your services and facility were a huge part of our achieving that successfully. Thank you!',
    author: 'Donna C.',
  },
]

interface Props {
  testimonials?: Quote[]
}

export const Testimonials: React.FC<Props> = ({ testimonials = DEFAULT_TESTIMONIALS }) => {
  return (
    <Showcase.Wrapper className={styles.background}>
      <Showcase.Secondary>
        <Showcase.Title>Dear Kalas</Showcase.Title>

        <p>
          At Kalas Funeral Home &amp; Crematory, we take pride in being voted the best area funeral
          home. Take a look at what some of the families we&apos;ve served have to say.
        </p>
      </Showcase.Secondary>

      <Showcase.Primary>
        <QuoteCarousel quotes={testimonials} variant={LightDarkVariant.LIGHT} />
      </Showcase.Primary>
    </Showcase.Wrapper>
  )
}
