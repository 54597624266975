import React from 'react'
import classnames from 'classnames'

import styles from './styles.module.scss'

const FakeRightArrowLink: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...rest }) => {
  return (
    <div
      aria-label="Learn more"
      role="link"
      className={classnames(styles.button, className)}
      {...rest}
    >
      <svg
        height="10"
        viewBox="0 0 10 10"
        width="10"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <filter id="a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
        <g fill="none" fillRule="evenodd" filter="url(#a)" transform="translate(-10 -10)">
          <path
            d="m5.383.131.059.052 4.375 4.375c.024.024.04.043.055.063l-.055-.063c.028.028.053.058.074.09l.029.046c.008.013.015.027.021.04.038.081.059.171.059.266 0 .033-.003.066-.008.098l-.01.054-.013.043-.015.04c-.007.018-.015.035-.023.051l-.015.028c-.012.02-.025.04-.038.057l-.01.012-.011.015c-.01.013-.021.025-.033.036l-.007.008-4.375 4.375c-.244.244-.64.244-.884 0-.225-.225-.243-.58-.052-.825l.052-.059 3.307-3.308h-7.24c-.345 0-.625-.28-.625-.625 0-.32.241-.585.552-.62l.073-.005h7.24l-3.307-3.308c-.225-.225-.243-.58-.052-.825l.052-.059c.225-.225.58-.243.825-.052z"
            fill="currentColor"
            fillRule="nonzero"
            transform="translate(10 10)"
          />
        </g>
      </svg>
    </div>
  )
}

export default FakeRightArrowLink
