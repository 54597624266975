import React from 'react'

import * as Showcase from '../Showcase'
import useFacebook from '../../hooks/useFacebook'
import styles from './styles.module.scss'

const RADIAN_STYLE: React.CSSProperties = {
  backgroundColor: '#bfd6e0',
  backgroundPosition: 'center 100%',
  backgroundSize: '960px 700px',
}

const SocialFeature: React.FC = () => {
  useFacebook()

  return (
    <Showcase.Wrapper backgroundImage="/radian.png" style={RADIAN_STYLE}>
      <Showcase.Secondary>
        <div
          className="fb-page"
          data-href="https://www.facebook.com/kalasFH"
          data-tabs=""
          data-width="1000"
          data-height="260"
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        />
      </Showcase.Secondary>

      <Showcase.Primary>
        <Showcase.Title className={styles.title}>Follow Us on Facebook</Showcase.Title>

        <Showcase.Text className={styles.text}>
          Join us as we explore what it means to celebrate life&apos;s journey, posting
          inspirational thoughts, humorous quotes, and helpful tips on how to navigate your grief
          journey.
        </Showcase.Text>
      </Showcase.Primary>
    </Showcase.Wrapper>
  )
}

export default SocialFeature
