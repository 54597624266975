import React from 'react'

const DEFAULT_TIMEOUT = 8000

export const useCarousel = <T = any>(
  carouselItems: T[],
  timeout: number = DEFAULT_TIMEOUT,
): { activeIndex: number; currentItem: T; next: () => void; previous: () => void } => {
  const items = React.useRef<T[]>(carouselItems)
  const [activeIndex, setActiveIndex] = React.useState(0)
  const currentItem = items.current[activeIndex]
  const next = React.useCallback(() => {
    const nextIndex = activeIndex + 1
    if (nextIndex >= items.current.length) {
      setActiveIndex(0)
    } else {
      setActiveIndex(nextIndex)
    }
  }, [activeIndex, setActiveIndex, items.current])
  const previous = React.useCallback(() => {
    const previousIndex = activeIndex - 1
    if (previousIndex < 0) {
      setActiveIndex(items.current.length - 1)
    } else {
      setActiveIndex(previousIndex)
    }
  }, [activeIndex, setActiveIndex, items.current])

  React.useEffect(() => {
    const innerTimeout = setTimeout(() => {
      next()
    }, timeout)

    return () => clearTimeout(innerTimeout)
  }, [next, timeout])

  return { activeIndex, currentItem, next, previous }
}
