import React from 'react'
import classnames from 'classnames'
import NextImage from 'next/image'
import { Spacer, WorkArea } from '@revelrygroup/components'

import styles from './style.module.scss'

export const Primary: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className={styles.primary}>{children}</div>
}

export const Secondary: React.FC<React.PropsWithChildren & { className?: string }> = ({
  children,
  className,
}) => {
  return <div className={classnames(styles.secondary, className)}>{children}</div>
}

interface VideoProps {
  src: string
}

export const Video: React.FC<VideoProps> = ({ src }) => {
  return (
    <div className={styles.videoContainer}>
      <iframe title="vimeo-player" src={src} frameBorder="0" allowFullScreen></iframe>
    </div>
  )
}

export const ImageContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className={styles.imageContainer}>{children}</div>
}

interface ImageProps {
  alt: string
  src: string
}

export const Image: React.FC<ImageProps> = ({ alt, src }) => {
  return (
    <ImageContainer>
      <NextImage alt={alt} height={365} layout="responsive" src={src} unoptimized width={650} />
    </ImageContainer>
  )
}

export const Title: React.FC<React.PropsWithChildren & { className?: string }> = ({
  children,
  className,
}) => (
  <Spacer verticalBottom="xs" verticalMdBottom="sm">
    <h2 className={className}>{children}</h2>
  </Spacer>
)

export const Text: React.FC<React.PropsWithChildren & { className?: string }> = ({
  children,
  className,
}) => {
  return (
    <Spacer verticalBottom="xs" verticalMdBottom="sm">
      <p className={className}>{children}</p>
    </Spacer>
  )
}

interface WrapperProps extends React.HTMLProps<HTMLDivElement> {
  backgroundImage?: string
}

export const Wrapper: React.FC<WrapperProps> = React.forwardRef<HTMLDivElement, WrapperProps>(
  ({ backgroundImage, children, className, ...rest }, ref) => {
    const style = backgroundImage
      ? { ...rest.style, backgroundImage: `url(${backgroundImage})` }
      : rest.style

    return (
      <WorkArea
        {...rest}
        className={classnames(styles.showcase, className)}
        style={style}
        ref={ref}
      >
        <div className={styles.content}>{children}</div>
      </WorkArea>
    )
  },
)

Wrapper.displayName = 'ShowcaseWrapper'
