export interface DeceasedSnippet {
  date_of_birth: string
  death_occurred_at: string
  first_name: string
  id: number
  image: string
  last_name: string
  middle_name: string
  obituary_text: string
}

export interface Deceased {
  date_of_birth: string
  date_of_death: string
  death_occurred_at: string
  first_name: string
  last_name: string
  middle_name: string | null
  obituary_text: string | null
  image: string | null
}

export interface ObituarySnippet {
  id: number
  enable_flowerstore: boolean
  deceased: DeceasedSnippet
  facility_id: number | null
  last_mod: string
}

export interface Quote {
  body: string
  author: string
}

export interface NavItem {
  item: string
  location: string
  activeMatch: RegExp
}

export interface SelectInputOption {
  label: string
  value: string
}

export interface ExternalDeliveryDatesResponse {
  errors?: string[]
  DATES?: string[]
}

export interface DeliveryDatesResponse {
  delivery_dates: string[]
}

export enum LifePageNavItem {
  OBITUARY,
  MEMORIES,
  SERVICES,
  FLOWERS,
  MEMORIAL_TREES,
}

export interface FlowerProduct {
  code: string
  description: string
  dimensions: string
  name: string
  price: number
  images: FlowerProductImages
}

export interface MemorialTree {
  description: string
  name: string
  price_in_cents: number
  product_id: string
  quantity: number
  num_trees: number
}

interface FlowerProductImages {
  small: string
  large: string
  xl: string
}

export interface ShoppingCart {
  delivery_charge_in_cents?: number
  errors: string[]
  external_id: string
  external_order_number?: string
  facility_id: number | null
  flower_order_total_in_cents: number
  flower_shopping_cart_items: FlowerCartProduct[]
  flower_subtotal_in_cents?: number
  id: number
  memorial_trees_total_in_cents?: number
  memorial_tree_shopping_cart_items: MemorialTree[]
  billing_address_line_1?: string
  billing_address_line_2?: string
  billing_city?: string
  billing_email?: string
  billing_first_name?: string
  billing_last_name?: string
  billing_phone?: string
  billing_state?: string
  billing_zip?: string
  num_trees?: number
  shipping_address_line_1?: string
  shipping_address_line_2?: string
  shipping_city?: string
  shipping_delivery_date?: string
  shipping_name?: string
  shipping_personal_message?: string
  shipping_phone?: string
  shipping_state?: string
  shipping_zip?: string
  tax_in_cents?: number
}

export interface GenerateTokenResponse {
  USERNAME: string
  AUTHORIZENET_KEY: string
  AUTHORIZENET_URL: string
}

export interface FlowerCartProduct {
  description: string
  external_id: string
  name: string
  price_in_cents: number
  quantity: number
}

export type ApiFlowerProduct = Pick<FlowerProduct, 'code' | 'description' | 'name' | 'price'>

export type ReactionByID = { [reactableID: number]: number }

export type FloristOneDateString = string

export enum LocationIdentifier {
  LOCATION1 = 'location1',
  LOCATION2 = 'location2',
  UNKNOWN = 'unknown',
}

export interface FuneralHome {
  name: string
  address_line_1: string
  address_line_2: string | null
  city: string
  id: number
  state: string
  zip: string
  phone_number: string | null
  email_address: string | null
  has_second_location: boolean
  logo_image: string | null
  location_2_address_line_1: string | null
  location_2_address_line_2: string | null
  location_2_city: string | null
  location_2_logo_image: string | null
  location_2_state: string | null
  location_2_zip: string | null
  location_2_phone_number: string | null
  location_2_email_address: string | null
  time_zone: string
}

export interface Venue {
  address_line_1: string
  address_line_2: string | null
  city: string
  image: string | null
  name: string
  state: string
  zip: string
}

export interface Case {
  id: number
  case_service_offerings: CaseServiceOffering[]
  deceased: Deceased
  enable_flowerstore: boolean
  facility_id: number | null
  location_identifier: LocationIdentifier
  earliest_delivery_date: string
  funeral_home: FuneralHome
  upcoming_service_start_date: string
}

export interface CaseServiceOffering {
  id: number
  ends_at: string | null
  service_offering_name: string
  starts_at: string | null
  venue: Venue | null
}

export interface VisitorUser {
  email: string
  location?: string
  name: string
}

export interface ObituaryMemory {
  case_id: number
  comments: ObituaryMemoryComment[]
  content?: string
  has_candle: boolean
  has_flowers: boolean
  has_trees: boolean
  id: number
  image?: string
  like_count: number
  recorded_at: string
  visitor_user: VisitorUser
}

export interface ObituaryMemoryParams {
  content?: string
  has_candle: boolean
  has_flowers: boolean
  has_trees: boolean
  visitor_user: VisitorUser
}

export enum Reactable {
  OBITUARY_MEMORY = 'ObituaryMemory',
  OBITUARY_MEMORY_COMMENT = 'ObituaryMemoryComment',
}

export enum ShippingFormVariant {
  LOCATION_1 = 'location1',
  LOCATION_2 = 'location2',
  CUSTOM = 'custom',
}

export interface Reaction {
  id: number
  visitor_user_id: number
}

export interface ReactionParams {
  reactable_type: Reactable
  reactable_id: number
  visitor_user_email: string | undefined
}

export interface ObituaryMemoryComment {
  obituary_memory_id: number
  content: string
  id: number
  like_count: number
  recorded_at: string
  visitor_user: VisitorUser
}

export interface ObituaryMemoryCommentParams {
  content: string
  visitor_user: VisitorUser
}

export interface ShippingData {
  name: string
  address_line_1: string
  address_line_2: string
  city: string
  state: string
  zip: string
  delivery_date: FloristOneDateString
  personal_message: string
  phone: string
  shippingFormVariant: ShippingFormVariant
}

export interface StoredBillingData {
  month: string
  year: string
  first_name: string
  last_name: string
  address_line_1: string
  address_line_2?: string
  city: string
  state: string
  zip: string
  email: string
  phone: string
}

export interface PaginationParams {
  limit: number
  page: number
}

export interface SearchPaginationParams extends PaginationParams {
  search?: string
}

export enum LightDarkVariant {
  LIGHT = 'light',
  DARK = 'dark',
}

export type HeaderVariant = 'branded' | 'brandedLight' | 'brandedLightV2'
