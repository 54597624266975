import React from 'react'
import classnames from 'classnames'

interface Props extends React.PropsWithChildren {
  className?: string
}

const H3: React.FC<Props> = ({ children, className }) => {
  return <h3 className={classnames('h3', className)}>{children}</h3>
}

export default H3
