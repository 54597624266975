import React from 'react'
import {
  ButtonVariant,
  Highlight,
  MaxWidthConstraint,
  OneByThreeGrid,
  OvalButton,
  Spacer,
  WorkArea,
} from '@revelrygroup/components'
import { GetServerSideProps } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'

import { API } from '../revelry_api'
import { FuneralHome, ObituarySnippet } from '../types'
import { FuneralHomeFooter } from '../components/FuneralHomeFooter'
import { FuneralHomeModel } from '../components/models/FuneralHomeModel'
import H3 from '../components/typography/H3'
import Layout from '../components/Layout'
import Obituaries from '../components/Obituaries'
import FakeRightArrowLink from '../components/buttons/FakeRightArrowLink'
import { routes } from '../routes'
import SocialFeature from '../components/SocialFeature'
import styles from '../styles/Home.module.scss'
import { Testimonials } from '../components/Testimonials'
import VideoHero from '../components/VideoHero'

interface Props {
  funeralHome: FuneralHome | null
  obituaries: ObituarySnippet[]
}

const Home: React.FC<Props> = ({ funeralHome, obituaries }) => {
  return (
    <>
      <Head>
        <title>
          {funeralHome?.name} - {FuneralHomeModel.headTagLocation(funeralHome)}
        </title>

        <meta
          name="description"
          key="description"
          content={`${funeralHome?.name} serves the ${FuneralHomeModel.headTagLocation(
            funeralHome,
            ' and ',
          )}${' '}
          ${
            funeralHome?.location_2_city ? 'regions' : 'region'
          } with obituaries and funeral services such as memorials, burials, viewings, embalmings, death planning and other end of life services. Contact us for consultation.`}
        />
      </Head>

      <Layout headerVariant="brandedLightV2">
        <VideoHero />

        <div className="height-100">
          <main>
            <WorkArea className={styles.highlightWorkarea}>
              <MaxWidthConstraint maxWidth={950}>
                <Spacer verticalBottom="sm" verticalLgBottom="md">
                  <p className={styles.atKalasText}>
                    At Kalas, we believe each life&apos;s journey deserves to be celebrated. We will
                    help you plan a celebration that is as unique as your loved one and the legacy
                    they leave behind—creating a special memory that will be passed on for
                    generations to come.
                  </p>
                </Spacer>
              </MaxWidthConstraint>

              <MaxWidthConstraint>
                <OneByThreeGrid>
                  <Link href={routes.planningAhead}>
                    <a>
                      <Highlight.Wrapper>
                        <Highlight.Primary>
                          <Image
                            alt="Planning ahead"
                            height={380}
                            layout="responsive"
                            src="/photo-planning-ahead@2x.png"
                            width={380}
                          />
                        </Highlight.Primary>

                        <Highlight.Secondary>
                          <H3>Planning Ahead</H3>

                          <FakeRightArrowLink className={styles.highlightButton} />
                        </Highlight.Secondary>
                      </Highlight.Wrapper>
                    </a>
                  </Link>

                  <Link href={routes.yourGriefJourney}>
                    <a>
                      <Highlight.Wrapper>
                        <Highlight.Primary>
                          <Image
                            alt="Grief journey"
                            height={380}
                            layout="responsive"
                            src="/photo-grief-journey@2x.png"
                            width={380}
                          />
                        </Highlight.Primary>

                        <Highlight.Secondary>
                          <H3>Your Grief Journey</H3>

                          <FakeRightArrowLink className={styles.highlightButton} />
                        </Highlight.Secondary>
                      </Highlight.Wrapper>
                    </a>
                  </Link>

                  <Link href={routes.about}>
                    <a>
                      <Highlight.Wrapper>
                        <Highlight.Primary>
                          <Image
                            alt="Kalas team"
                            height={380}
                            layout="responsive"
                            src="/photo-kalas-group@2x.jpg"
                            width={380}
                          />
                        </Highlight.Primary>

                        <Highlight.Secondary>
                          <H3>Our Story</H3>

                          <FakeRightArrowLink className={styles.highlightButton} />
                        </Highlight.Secondary>
                      </Highlight.Wrapper>
                    </a>
                  </Link>
                </OneByThreeGrid>
              </MaxWidthConstraint>
            </WorkArea>

            <WorkArea className={styles.obituaryWorkArea}>
              <h2 className={styles.h2}>Celebrating the Life of...</h2>

              <MaxWidthConstraint>
                <Obituaries obituaries={obituaries} />
              </MaxWidthConstraint>

              <div className={styles.allCelebrationsBtnWrapper}>
                <OvalButton
                  as="Link"
                  href={routes.obituaries}
                  variant={ButtonVariant.INVERSE_LIGHT}
                >
                  More Obituaries
                </OvalButton>
              </div>
            </WorkArea>

            <SocialFeature />

            <Testimonials />
          </main>

          <FuneralHomeFooter />
        </div>
      </Layout>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {
  try {
    const [obituaries, funeralHome] = await Promise.all([
      API.obituarySnippets.loadAll({ limit: 12, page: 1 }),
      API.funeralHomes.load(),
    ])

    return { props: { obituaries, funeralHome } }
  } catch (e) {
    return { props: { obituaries: [], funeralHome: null } }
  }
}

export default Home
