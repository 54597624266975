import React from 'react'
import classnames from 'classnames'

import { LightDarkVariant } from '../../../types'
import styles from './styles.module.scss'

interface Props extends React.HTMLProps<HTMLButtonElement> {
  variant: LightDarkVariant
}

export const OutlineRightArrowButton: React.FC<Props> = (props) => {
  return (
    <button
      {...props}
      className={classnames(styles.btn, styles[`btn${props.variant}`], props.className)}
      type="button"
    />
  )
}
