import { Grid } from '@revelrygroup/components'
import Image from 'next/image'
import Link from 'next/link'

import Obituary from '../models/Obituary'
import { ObituarySnippet } from '../../types'
import { routes } from '../../routes'
import styles from './styles.module.scss'

interface Props {
  obituaries: ObituarySnippet[]
}

const Obituaries: React.FC<Props> = ({ obituaries }) => {
  return (
    <Grid.Wrapper>
      {obituaries.map((obituary) => {
        const href = routes.obituary(obituary)

        return (
          <Link href={href} key={obituary.id}>
            <a>
              <Grid.Item>
                <Grid.ImageWrapper>
                  <Image
                    alt={Obituary.deceasedFullName(obituary, { includeMiddleName: true })}
                    className={styles.image}
                    layout="fill"
                    objectFit="cover"
                    priority
                    src={obituary.deceased.image || '/default-obit.jpg'}
                    unoptimized
                  />
                </Grid.ImageWrapper>

                <Grid.Title>
                  {obituary.deceased.first_name} {obituary.deceased.last_name}
                </Grid.Title>

                <Grid.SubTitle>{obituary.deceased.death_occurred_at}</Grid.SubTitle>
              </Grid.Item>
            </a>
          </Link>
        )
      })}
    </Grid.Wrapper>
  )
}

export default Obituaries
