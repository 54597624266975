import { FuneralHome } from '../../../types'

export const FuneralHomeModel = {
  hasSecondLocation: (funeralHome: FuneralHome): boolean => {
    return (
      [
        funeralHome.location_2_address_line_1,
        funeralHome.location_2_city,
        funeralHome.location_2_state,
        funeralHome.location_2_zip,
      ].every(Boolean) && !!funeralHome.has_second_location
    )
  },
  headTagLocation: (funeralHome: FuneralHome | null, delimeter = ', '): string => {
    if (!funeralHome) {
      return ''
    }

    if (funeralHome.location_2_city) {
      return `${funeralHome.city}${delimeter}${funeralHome.location_2_city}, ${funeralHome.state}`
    }

    return `${funeralHome.city}, ${funeralHome.state}`
  },
}
