import React from 'react'

import styles from './styles.module.scss'

const VideoHero: React.FC = () => {
  return (
    <div className={styles.hero}>
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          src="https://player.vimeo.com/video/932868057?background=1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          title="KalasFunerHomesdotcom"
        ></iframe>
      </div>

      <div className={styles.content}>
        <h1 className={styles.h1}>Celebrating Life&apos;s Journey</h1>
      </div>
    </div>
  )
}

export default VideoHero
